import React, { useState, useEffect, useRef } from "react";
import { PageHeader, Button, Typography, Space } from "antd";
import { MyBreadcrumb, countryFullName } from "utils";
import { PrinterOutlined } from "@ant-design/icons";
import { ispViewByIdService } from "services/isp";
import { handleApiError, getServiceAreaName } from "utils";
import { useReactToPrint } from "react-to-print";
import * as moment from "moment";

const { Title } = Typography;
export default function View(props) {
  const { id } = props.match.params;
  const [isLoading, setIsLoading] = useState(false);
  const [ispInfo, setISPInfo] = useState({});
  const componentRef = useRef(null);

  useEffect(() => {
    // IF id exist the initialize form data
    if (id) {
      ispViewByIdService(id)
        .then((response) => {
          setIsLoading(false);
          console.log("RESPONSE", response.data);
          if (response.data.item) {
            setISPInfo(response.data.item);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    }
  }, [id]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <MyBreadcrumb
        items={[
          { label: "ISP", path: "/isp" },
          { label: "View", path: "" },
        ]}
      />
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="View"
        subTitle=""
        extra={[
          <Button
            key="1"
            onClick={handlePrint}
            type="primary"
            shape="round"
            icon={<PrinterOutlined />}
          >
            Print
          </Button>,
        ]}
      >
        {isLoading ? "Loading..." : ""}

        <div ref={componentRef}>
          <div className="descriptionView2-heading">
            <Title level={3}>
              ISP - {ispInfo?.isp_provider?.provider_name}
            </Title>
            <Title level={3}>
              {ispInfo?.branch?.physical_city} {ispInfo?.branch?.market_name}{" "}
              {ispInfo?.branch?.building_id
                ? " - " + ispInfo?.branch?.building_id
                : ""}
            </Title>
            <Title level={3}>
              Status:{" "}
              {ispInfo.status === "ACTIVE" ? (
                <span className="text-green">Active</span>
              ) : (
                <span className="text-yellow">{ispInfo.status}</span>
              )}{" "}
              - {ispInfo?.download_speed_mbps} down /{" "}
              {ispInfo?.upload_speed_mbps} up
            </Title>
            {ispInfo.monthly_fee && (
              <Title level={3}>
                Monthly Fee:{" "}
                <span className="text-green">$ {ispInfo.monthly_fee}</span>
              </Title>
            )}
          </div>

          <div className="descriptionView2">
            <div className="box">
              <Title level={4}>Physical Address</Title>
              {ispInfo?.branch?.physical_address && (
                <div>{ispInfo?.branch?.physical_address}</div>
              )}
              {ispInfo?.branch?.physical_suite_apt && (
                <div>{ispInfo?.branch?.physical_suite_apt}</div>
              )}
              {ispInfo?.branch?.physical_city && (
                <div>
                  {ispInfo?.branch?.physical_city},{" "}
                  {ispInfo?.branch?.physical_state || ""}{" "}
                  {ispInfo?.branch?.physical_zip || ""}
                </div>
              )}
              {ispInfo?.branch?.physical_map_url && (
                <div>
                  <a
                    href={ispInfo.branch.physical_map_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {ispInfo?.branch?.physical_map_url}
                  </a>
                </div>
              )}
              {ispInfo?.branch?.main_phone_1 && (
                <div>
                  <strong>Phone:</strong> {ispInfo?.branch?.main_phone_1}
                </div>
              )}

              {ispInfo?.branch?.location_hours && (
                <div>
                  <strong>Location Hours:</strong>{" "}
                  {ispInfo?.branch?.location_hours}
                </div>
              )}
            </div>

            <div className="box">
              <Title level={4}>Provider Details</Title>
              {ispInfo?.isp_provider?.provider_name && (
                <div>{ispInfo?.isp_provider?.provider_name}</div>
              )}
              <div className="flex flex-col">
                {ispInfo?.isp_provider?.isp_contact_address1 && (
                  <div>{ispInfo?.isp_provider?.isp_contact_address1}</div>
                )}
                {ispInfo?.isp_provider?.isp_contact_address2 && (
                  <div>{ispInfo?.isp_provider?.isp_contact_address2}</div>
                )}
                {ispInfo?.isp_provider?.isp_contact_city && (
                  <div>
                    {ispInfo?.isp_provider?.isp_contact_city},{" "}
                    {ispInfo?.isp_provider?.isp_contact_state || ""}{" "}
                    {ispInfo?.isp_provider?.isp_contact_zip || ""}
                  </div>
                )}
                {ispInfo?.isp_provider?.isp_contact_country && (
                  <div>
                    {countryFullName(
                      ispInfo?.isp_provider?.isp_contact_country
                    )}
                  </div>
                )}
              </div>
              {ispInfo?.isp_provider?.isp_contact_phone && (
                <div>
                  <strong>Phone:</strong>{" "}
                  {ispInfo?.isp_provider?.isp_contact_phone}
                </div>
              )}

              {(ispInfo?.account_number ||
                ispInfo?.node_id ||
                ispInfo?.circuit_id) && (
                <div className="mt-10">
                  <Title level={5}>
                    <strong>Account Details</strong>
                  </Title>
                  {ispInfo?.branch.account_number && (
                    <div>
                      <strong>Branch Account Number: </strong>
                      {ispInfo?.branch.account_number || ""}
                    </div>
                  )}
                  {ispInfo?.account_number && (
                    <div>
                      <strong>ISP Account Number: </strong>
                      {ispInfo?.account_number || ""}
                    </div>
                  )}

                  {ispInfo?.node_id && (
                    <div>
                      <strong>Node ID: </strong> {ispInfo?.node_id}
                    </div>
                  )}
                  {ispInfo?.circuit_id && (
                    <div>
                      <strong>Circuit ID: </strong>
                      {ispInfo?.circuit_id}
                    </div>
                  )}
                </div>
              )}
            </div>

            {(ispInfo?.billing_name ||
              ispInfo?.billing_address1 ||
              ispInfo?.billing_state ||
              ispInfo?.billing_city ||
              ispInfo?.billing_zip ||
              ispInfo?.billing_contact) && (
              <div className="box">
                <Title level={4}>
                  <strong>Billing Details</strong>
                </Title>
                {ispInfo?.billing_name && <div>{ispInfo?.billing_name}</div>}
                {ispInfo?.billing_address1 && (
                  <div>{ispInfo?.billing_address1}</div>
                )}
                {ispInfo?.billing_address2 && (
                  <div>{ispInfo?.billing_address2}</div>
                )}
                <div className="flex flex-col">
                  {ispInfo?.billing_state && (
                    <div>
                      {ispInfo?.billing_state}, {ispInfo?.billing_city || ""}{" "}
                      {ispInfo?.billing_zip || ""}
                    </div>
                  )}
                  {ispInfo?.billing_country && (
                    <div>{countryFullName(ispInfo?.billing_country)}</div>
                  )}
                </div>

                <div>
                  <strong>Contact: </strong>
                  {ispInfo?.billing_contact ? (
                    <span>{ispInfo?.billing_contact}</span>
                  ) : (
                    "NA"
                  )}
                </div>
                <div>
                  <strong>Phone: </strong>
                  {ispInfo?.billing_phone ? (
                    <span>{ispInfo?.billing_phone}</span>
                  ) : (
                    "NA"
                  )}
                </div>
                <div>
                  <strong>Email: </strong>
                  {ispInfo?.billing_email ? (
                    <span>{ispInfo?.billing_email}</span>
                  ) : (
                    "NA"
                  )}
                </div>
                <div>
                  <strong>Fax: </strong>
                  {ispInfo?.billing_fax ? (
                    <span>{ispInfo?.billing_fax}</span>
                  ) : (
                    "NA"
                  )}
                </div>
              </div>
            )}

            {(ispInfo?.billing_name ||
              ispInfo?.billing_address1 ||
              ispInfo?.billing_state ||
              ispInfo?.billing_city ||
              ispInfo?.billing_zip ||
              ispInfo?.billing_contact) && (
              <div className="box">
                <Title level={4}>
                  <strong>Other Contact Info</strong>
                </Title>

                <div className="mt-10">
                  <Title level={5} style={{ marginBottom: 0 }}>
                    <strong>Main Customer Contact</strong>
                  </Title>
                  <div>
                    <strong>Name: </strong>
                    {ispInfo?.billing_contact ? (
                      <span>{ispInfo?.billing_contact}</span>
                    ) : (
                      "NA"
                    )}
                  </div>
                  <div>
                    <strong>Phone: </strong>
                    {ispInfo?.billing_phone ? (
                      <span>{ispInfo?.billing_phone}</span>
                    ) : (
                      "NA"
                    )}
                  </div>
                  <div>
                    <strong>Email: </strong>
                    {ispInfo?.billing_email ? (
                      <span>{ispInfo?.billing_email}</span>
                    ) : (
                      "NA"
                    )}
                  </div>
                </div>

                <div className="mt-10">
                  <Title level={5} style={{ marginBottom: 0 }}>
                    <strong>Main Technical Contact</strong>
                  </Title>
                  <div>
                    <strong>Name: </strong>
                    {ispInfo?.billing_contact ? (
                      <span>{ispInfo?.billing_contact}</span>
                    ) : (
                      "NA"
                    )}
                  </div>
                  <div>
                    <strong>Phone: </strong>
                    {ispInfo?.billing_phone ? (
                      <span>{ispInfo?.billing_phone}</span>
                    ) : (
                      "NA"
                    )}
                  </div>
                  <div>
                    <strong>Email: </strong>
                    {ispInfo?.billing_email ? (
                      <span>{ispInfo?.billing_email}</span>
                    ) : (
                      "NA"
                    )}
                  </div>
                </div>

                <div className="mt-10">
                  <Title level={5} style={{ marginBottom: 0 }}>
                    <strong>Main On-site Contact</strong>
                  </Title>
                  <div>
                    <strong>Name: </strong>
                    {ispInfo?.billing_contact ? (
                      <span>{ispInfo?.billing_contact}</span>
                    ) : (
                      "NA"
                    )}
                  </div>
                  <div>
                    <strong>Phone: </strong>
                    {ispInfo?.billing_phone ? (
                      <span>{ispInfo?.billing_phone}</span>
                    ) : (
                      "NA"
                    )}
                  </div>
                  <div>
                    <strong>Email: </strong>
                    {ispInfo?.billing_email ? (
                      <span>{ispInfo?.billing_email}</span>
                    ) : (
                      "NA"
                    )}
                  </div>
                </div>

                {(ispInfo?.isp_provider?.isp_contact_address1 ||
                  ispInfo?.isp_provider?.isp_contact_address2 ||
                  ispInfo?.isp_provider?.isp_contact_state ||
                  ispInfo?.isp_provider?.isp_contact_city ||
                  ispInfo?.isp_provider?.isp_contact_phone) && (
                  <div className="mt-10">
                    <Title level={5} style={{ marginBottom: 0 }}>
                      <strong>ISP Contact</strong>
                    </Title>
                    {ispInfo?.isp_provider?.isp_contact_address1 && (
                      <div>{ispInfo?.isp_provider?.isp_contact_address1}</div>
                    )}
                    {ispInfo?.isp_provider?.isp_contact_address2 && (
                      <div>{ispInfo?.isp_contact_address2}</div>
                    )}
                    {ispInfo?.isp_provider?.isp_contact_state && (
                      <div>
                        {ispInfo?.isp_provider?.isp_contact_state},{" "}
                        {ispInfo?.isp_provider?.isp_contact_city}
                      </div>
                    )}{" "}
                    {ispInfo?.isp_provider?.isp_contact_country && (
                      <div>
                        {countryFullName(
                          ispInfo?.isp_provider?.isp_contact_country
                        )}
                      </div>
                    )}
                    {ispInfo?.isp_provider?.isp_contact_phone && (
                      <div>
                        <strong>Phone:</strong>{" "}
                        {ispInfo?.isp_provider?.isp_contact_phone}
                      </div>
                    )}
                  </div>
                )}

                {(ispInfo?.isp_sales_agent_name ||
                  ispInfo?.isp_sales_agent_phone ||
                  ispInfo?.isp_sales_agent_email) && (
                  <div className="mt-10">
                    <Title level={5} style={{ marginBottom: 0 }}>
                      <strong>ISP: Sales Agent</strong>
                    </Title>
                    {ispInfo?.isp_sales_agent_name && (
                      <div>{ispInfo?.isp_sales_agent_name}</div>
                    )}
                    {ispInfo?.isp_sales_agent_phone && (
                      <div>{ispInfo?.isp_sales_agent_phone}</div>
                    )}
                    {ispInfo?.isp_sales_agent_email && (
                      <div>{ispInfo?.isp_sales_agent_email}</div>
                    )}
                  </div>
                )}
              </div>
            )}

            {(ispInfo?.pin ||
              ispInfo?.security_question ||
              ispInfo?.phone_number ||
              ispInfo?.website ||
              ispInfo?.username ||
              ispInfo?.password) && (
              <div className="box">
                <Title level={4}>
                  <strong>Primary User</strong>
                </Title>
                <div>
                  <strong>Primary on Account: </strong>
                  {ispInfo?.primary_on_account ? (
                    <span>{ispInfo?.primary_on_account}</span>
                  ) : (
                    "NA"
                  )}
                </div>
                <div>
                  <strong>PIN: </strong>
                  {ispInfo?.pin ? <span>{ispInfo?.pin}</span> : "NA"}
                </div>
                <div>
                  <strong>Security Question: </strong>
                  {ispInfo?.security_question ? (
                    <span>{ispInfo?.security_question}</span>
                  ) : (
                    "NA"
                  )}
                </div>
                <div>
                  <strong>Phone Number: </strong>
                  {ispInfo?.phone_number ? (
                    <span>{ispInfo?.phone_number}</span>
                  ) : (
                    "NA"
                  )}
                </div>
                <div>
                  <strong>Username: </strong>
                  {ispInfo?.username ? <span>{ispInfo?.username}</span> : "NA"}
                </div>
                <div>
                  <strong>Password: </strong>
                  {ispInfo?.password ? <span>{ispInfo?.password}</span> : "NA"}
                </div>
                <div>
                  <strong>Website: </strong>
                  {ispInfo?.website ? (
                    <span>
                      <a
                        href={ispInfo.website}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {ispInfo?.website}
                      </a>
                    </span>
                  ) : (
                    "NA"
                  )}
                </div>
              </div>
            )}

            {(ispInfo?.notices_address1 ||
              ispInfo?.notices_address2 ||
              ispInfo?.notices_state ||
              ispInfo?.notices_city) && (
              <div className="box">
                <Title level={4}>
                  <strong>Location Notices</strong>
                </Title>

                {ispInfo?.notices_address1 && (
                  <div>{ispInfo?.notices_address1}</div>
                )}
                {ispInfo?.notices_address2 && (
                  <div>{ispInfo?.notices_address2}</div>
                )}
                <Space className="flex">
                  {ispInfo?.notices_city && <div>{ispInfo?.notices_city}</div>}
                  {ispInfo?.notices_state && (
                    <div>{ispInfo?.notices_state}</div>
                  )}
                  {ispInfo?.notices_zip && <div>{ispInfo?.notices_zip}</div>}
                </Space>
                {ispInfo?.notices_country && (
                  <div>{countryFullName(ispInfo?.notices_country)}</div>
                )}
              </div>
            )}

            <div className="box">
              <Title level={4}>
                <strong>Terms</strong>
              </Title>
              <div>
                <strong>SLA: </strong>
                {ispInfo?.sla ? <span>{ispInfo?.sla}</span> : "NA"}
              </div>
              <div>
                <strong>Contract Start: </strong>
                {ispInfo?.contract_start ? (
                  <span>
                    {moment(ispInfo?.contract_start).format("ddd MMM DD YYYY")}
                  </span>
                ) : (
                  "NA"
                )}
              </div>
              <div>
                <strong>Contract End: </strong>
                {ispInfo?.contract_end ? (
                  <span>
                    {moment(ispInfo?.contract_end).format("ddd MMM DD YYYY")}
                  </span>
                ) : (
                  "NA"
                )}
              </div>
              <div>
                <strong>Term: </strong>
                {ispInfo?.term_length_months ? (
                  <span>{ispInfo?.term_length_months}</span>
                ) : (
                  "NA"
                )}
              </div>
              <div>
                <strong>Net ON/Off: </strong>
                {ispInfo?.onoff_net ? <span>{ispInfo?.onoff_net}</span> : "NA"}
              </div>
              <div>
                <strong>Signed By: </strong>
                {ispInfo?.contract_signedby ? (
                  <span>{ispInfo?.contract_signedby}</span>
                ) : (
                  "NA"
                )}
              </div>
              <div>
                <strong>Installation Fee: </strong>
                {ispInfo?.installation_fee ? (
                  <span>$ {ispInfo?.installation_fee}</span>
                ) : (
                  "NA"
                )}
              </div>
            </div>

            {(ispInfo?.isp_provider?.isp_billing_address1 ||
              ispInfo?.isp_provider?.isp_billing_address2 ||
              ispInfo?.isp_provider?.isp_billing_state ||
              ispInfo?.isp_provider?.isp_billing_city ||
              ispInfo?.isp_provider?.isp_billing_phone) && (
              <div className="box">
                <Title level={4}>
                  <strong>ISP Billing</strong>
                </Title>
                {ispInfo?.isp_provider?.isp_billing_address1 && (
                  <div>{ispInfo?.isp_provider?.isp_billing_address1}</div>
                )}
                {ispInfo?.isp_provider?.billing_address2 && (
                  <div>{ispInfo?.billing_address2}</div>
                )}
                {ispInfo?.isp_provider?.isp_billing_state && (
                  <div>
                    {ispInfo?.isp_provider?.isp_billing_state},{" "}
                    {ispInfo?.isp_provider?.isp_billing_city || ""}
                  </div>
                )}{" "}
                {ispInfo?.isp_provider?.isp_billing_country && (
                  <div>
                    {countryFullName(
                      ispInfo?.isp_provider?.isp_billing_country
                    )}
                  </div>
                )}
                {ispInfo?.isp_provider?.isp_billing_phone && (
                  <div>
                    <strong>Phone:</strong>{" "}
                    {ispInfo?.isp_provider?.isp_billing_phone}
                  </div>
                )}
              </div>
            )}

            {(ispInfo?.isp_provider?.isp_support_phone_number ||
              ispInfo?.isp_provider?.isp_support_website ||
              ispInfo?.isp_provider?.isp_support_email) && (
              <div className="box">
                <Title level={4}>
                  <strong>ISP Support</strong>
                </Title>
                {ispInfo?.isp_provider?.isp_support_phone_number && (
                  <div>{ispInfo?.isp_provider?.isp_support_phone_number}</div>
                )}
                {ispInfo?.isp_provider?.isp_support_website && (
                  <div>{ispInfo?.isp_support_website}</div>
                )}
                {ispInfo?.isp_provider?.isp_support_email && (
                  <div>{ispInfo?.isp_provider?.isp_support_email}</div>
                )}
              </div>
            )}

            {(ispInfo?.isp_provider?.isp_termination_phone ||
              ispInfo?.isp_provider?.isp_termination_email) && (
              <div className="box">
                <Title level={4}>
                  <strong>ISP Termination</strong>
                </Title>
                {ispInfo?.isp_provider?.isp_termination_phone && (
                  <div>
                    <strong>Phone:</strong>{" "}
                    {ispInfo?.isp_provider?.isp_termination_phone}
                  </div>
                )}
                {ispInfo?.isp_provider?.isp_termination_email && (
                  <div>{ispInfo?.isp_provider?.isp_termination_email}</div>
                )}
              </div>
            )}

            {(ispInfo?.isp_provider?.isp_sales_agent_name ||
              ispInfo?.isp_provider?.isp_sales_agent_phone ||
              ispInfo?.isp_provider?.isp_sales_agent_email) && (
              <div className="box">
                <Title level={4}>
                  <strong>Sales Agent</strong>
                </Title>
                {ispInfo?.isp_provider?.isp_sales_agent_name && (
                  <div>{ispInfo?.isp_provider?.isp_sales_agent_name}</div>
                )}
                {ispInfo?.isp_provider?.isp_sales_agent_phone && (
                  <div>{ispInfo?.isp_provider?.isp_sales_agent_phone}</div>
                )}
                {ispInfo?.isp_provider?.isp_sales_agent_email && (
                  <div>{ispInfo?.isp_provider?.isp_sales_agent_email}</div>
                )}
              </div>
            )}

            {(ispInfo?.isp_provider?.isp_sales_phone ||
              ispInfo?.isp_provider?.isp_sales_email) && (
              <div className="box">
                <Title level={4}>
                  <strong>ISP Sales</strong>
                </Title>
                {ispInfo?.isp_provider?.isp_sales_phone && (
                  <div>{ispInfo?.isp_provider?.isp_sales_phone}</div>
                )}
                {ispInfo?.isp_provider?.isp_sales_email && (
                  <div>{ispInfo?.isp_provider?.isp_sales_email}</div>
                )}
              </div>
            )}
          </div>

          {ispInfo?.branch?.notes && (
            <div className="descriptionView2">
              <div className="box-row">
                <strong>Notes: </strong>
                {ispInfo?.branch?.notes ? (
                  <span>{ispInfo?.branch?.notes}</span>
                ) : (
                  "NA"
                )}
              </div>
            </div>
          )}

          <div className="descriptionView2 mt-10">
            <div className="box-row h-10 bg-black">Connection Details</div>

            {ispInfo?.demarc && (
              <div className="box">
                <div>
                  <strong>Demarc: </strong>
                  {ispInfo?.demarc ? <span>{ispInfo?.demarc}</span> : "NA"}
                </div>
              </div>
            )}
            <div className="box">
              <div>
                <strong>Link Type: </strong>
                {ispInfo?.link_type ? <span>{ispInfo?.link_type}</span> : "NA"}
              </div>
              <div>
                <strong>Interface Type: </strong>
                {ispInfo?.interface_type ? (
                  <span>{ispInfo?.interface_type}</span>
                ) : (
                  "NA"
                )}
              </div>
              <div>
                <strong>Speed: </strong>
                {ispInfo?.speed ? <span>{ispInfo?.speed}</span> : "NA"}
              </div>
              <div>
                <strong>Duplex: </strong>
                {ispInfo?.duplex ? <span>{ispInfo?.duplex}</span> : "NA"}
              </div>
              <div>
                <strong>Circuit Use: </strong>
                {ispInfo?.circuit_use ? (
                  <span>{ispInfo?.circuit_use}</span>
                ) : (
                  "NA"
                )}
              </div>
              <div>
                <strong>Failover: </strong>
                {ispInfo?.failover ? <span>{ispInfo?.failover}</span> : "NA"}
              </div>
            </div>
            {ispInfo?.rack_info && (
              <div className="box-row">
                <div>
                  <strong>Rack Info: </strong>
                  {ispInfo?.rack_info ? (
                    <span>{ispInfo?.rack_info}</span>
                  ) : (
                    "NA"
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="descriptionView2 mt-10">
            <div className="box-row h-10 bg-black">Branch Network</div>
            <div className="box">
              <div>
                <strong>CIDR: </strong>
                {ispInfo?.isp_cidr ? <span>{ispInfo?.isp_cidr}</span> : "NA"}
              </div>
              <div>
                <strong>Subnet Mask: </strong>
                {ispInfo?.branch_subnet_mask ? (
                  <span>{ispInfo?.branch_subnet_mask}</span>
                ) : (
                  "NA"
                )}
              </div>
              <div>
                <strong>IPv4 Address Block: </strong>
                {ispInfo?.branch_ipv4_address_block ? (
                  <span>{ispInfo?.branch_ipv4_address_block}</span>
                ) : (
                  "NA"
                )}
              </div>
              <div>
                <strong>Gateway: </strong>
                {ispInfo?.carrier_ip_gateway ? (
                  <span>{ispInfo?.carrier_ip_gateway}</span>
                ) : (
                  "NA"
                )}
              </div>
              <div>
                <strong>WAN: </strong>
                {ispInfo?.branch_wan ? (
                  <span>{ispInfo?.branch_wan}</span>
                ) : (
                  "NA"
                )}
              </div>
            </div>

            {(ispInfo?.branch_first_assignable_ip ||
              ispInfo?.branch_usable_ip_range ||
              ispInfo?.branch_broadcast_ip) && (
              <div className="box">
                <div>
                  <strong>First Assignable: </strong>
                  {ispInfo?.branch_first_assignable_ip ? (
                    <span>{ispInfo?.branch_first_assignable_ip}</span>
                  ) : (
                    "NA"
                  )}
                </div>
                <div>
                  <strong>Last Assignable: </strong>
                  {ispInfo?.branch_last_assignable_ip ? (
                    <span>{ispInfo?.branch_last_assignable_ip}</span>
                  ) : (
                    "NA"
                  )}
                </div>
                <div>
                  <strong>Broadcast: </strong>
                  {ispInfo?.branch_broadcast_ip ? (
                    <span>{ispInfo?.branch_broadcast_ip}</span>
                  ) : (
                    "NA"
                  )}
                </div>
              </div>
            )}

            {(ispInfo?.isp_dns_resolver1 ||
              ispInfo?.isp_dns_resolver2 ||
              ispInfo?.isp_dns_authoritative1 ||
              ispInfo?.isp_dns_authoritative2) && (
              <div className="box">
                <div>
                  <strong>DNS Resolver 1: </strong>
                  {ispInfo?.isp_dns_resolver1 ? (
                    <span>{ispInfo?.isp_dns_resolver1}</span>
                  ) : (
                    "NA"
                  )}
                </div>
                <div>
                  <strong>DNS Resolver 2: </strong>
                  {ispInfo?.isp_dns_resolver2 ? (
                    <span>{ispInfo?.isp_dns_resolver2}</span>
                  ) : (
                    "NA"
                  )}
                </div>

                <div>
                  <strong>DNS Auth 1: </strong>
                  {ispInfo?.isp_dns_authoritative1 ? (
                    <span>{ispInfo?.isp_dns_authoritative1}</span>
                  ) : (
                    "NA"
                  )}
                </div>

                <div>
                  <strong>DNS Auth 2: </strong>
                  {ispInfo?.isp_dns_authoritative2 ? (
                    <span>{ispInfo?.isp_dns_authoritative2}</span>
                  ) : (
                    "NA"
                  )}
                </div>
              </div>
            )}

            {(ispInfo?.branch_ipv6_address_block ||
              ispInfo?.branch_ip_gateway_ipv6 ||
              ispInfo?.branch_first_assignable_ipv6) && (
              <div className="box">
                <div>
                  <strong>IPv6 Address Block: </strong>
                  {ispInfo?.branch_ipv6_address_block ? (
                    <span>{ispInfo?.branch_ipv6_address_block}</span>
                  ) : (
                    "NA"
                  )}
                </div>
                <div>
                  <strong>IPv6 Gateway: </strong>
                  {ispInfo?.branch_ip_gateway_ipv6 ? (
                    <span>{ispInfo?.branch_ip_gateway_ipv6}</span>
                  ) : (
                    "NA"
                  )}
                </div>

                <div>
                  <strong>First Assignable IPv6: </strong>
                  {ispInfo?.branch_first_assignable_ipv6 ? (
                    <span>{ispInfo?.branch_first_assignable_ipv6}</span>
                  ) : (
                    "NA"
                  )}
                </div>
              </div>
            )}
          </div>

          {(ispInfo?.carrier_wan_cidr ||
            ispInfo?.carrier_subnet_mask ||
            ispInfo?.carrier_wan ||
            ispInfo?.carrier_ip_gateway ||
            ispInfo?.carrier_usable_ip_range ||
            ispInfo?.carrier_ip_gateway_ipv6 ||
            ispInfo?.isp_dns_resolver_ipv6_1 ||
            ispInfo?.isp_dns_resolver_ipv6_2 ||
            ispInfo?.isp_provider?.wan_cr_serial_ip_address ||
            ispInfo?.isp_provider?.wan_cr_serial_ip_address_ipv6 ||
            ispInfo?.isp_provider?.wan_cr_serial_ip_address_ipv6_compressed ||
            ispInfo?.wan_ar_serial_ip_address ||
            ispInfo?.wan_ar_serial_ip_address_ipv6 ||
            ispInfo?.wan_ar_serial_ip_address_ipv6_compressed) && (
            <div className="descriptionView2 mt-10">
              <div className="box-row h-10 bg-black">Carrier Network</div>

              {(ispInfo?.carrier_wan_cidr ||
                ispInfo?.carrier_subnet_mask ||
                ispInfo?.carrier_wan ||
                ispInfo?.carrier_ip_gateway) && (
                <div className="box">
                  <div>
                    <strong>CIDR: </strong>
                    {ispInfo?.carrier_wan_cidr ? (
                      <span>{ispInfo?.carrier_wan_cidr}</span>
                    ) : (
                      "NA"
                    )}
                  </div>
                  <div>
                    <strong>Subnet Mask: </strong>
                    {ispInfo?.carrier_subnet_mask ? (
                      <span>{ispInfo?.carrier_subnet_mask}</span>
                    ) : (
                      "NA"
                    )}
                  </div>
                  <div>
                    <strong>Gateway: </strong>
                    {ispInfo?.carrier_ip_gateway ? (
                      <span>{ispInfo?.carrier_ip_gateway}</span>
                    ) : (
                      "NA"
                    )}
                  </div>
                  <div>
                    <strong>WAN: </strong>
                    {ispInfo?.carrier_wan ? (
                      <span>{ispInfo?.carrier_wan}</span>
                    ) : (
                      "NA"
                    )}
                  </div>
                </div>
              )}

              {(ispInfo?.carrier_usable_ip_range ||
                ispInfo?.carrier_ip_gateway_ipv6) && (
                <div className="box">
                  <div>
                    <strong>Usable IP Range: </strong>
                    {ispInfo?.carrier_usable_ip_range ? (
                      <span>{ispInfo?.carrier_usable_ip_range}</span>
                    ) : (
                      "NA"
                    )}
                  </div>
                  <div>
                    <strong>IPv6: </strong>
                    {ispInfo?.carrier_ip_gateway_ipv6 ? (
                      <span>{ispInfo?.carrier_ip_gateway_ipv6}</span>
                    ) : (
                      "NA"
                    )}
                  </div>
                </div>
              )}

              {(ispInfo?.isp_dns_resolver_ipv6_1 ||
                ispInfo?.isp_dns_resolver_ipv6_2) && (
                <div className="box-row">
                  <div>
                    <strong>DNS Resolver IPv6 1: </strong>
                    {ispInfo?.isp_dns_resolver_ipv6_1 ? (
                      <span>{ispInfo?.isp_dns_resolver_ipv6_1}</span>
                    ) : (
                      "NA"
                    )}
                  </div>
                  <div>
                    <strong>DNS Resolver IPv6 2: </strong>
                    {ispInfo?.isp_dns_resolver_ipv6_2 ? (
                      <span>{ispInfo?.isp_dns_resolver_ipv6_2}</span>
                    ) : (
                      "NA"
                    )}
                  </div>
                </div>
              )}

              {(ispInfo?.isp_provider?.wan_cr_serial_ip_address ||
                ispInfo?.isp_provider?.wan_cr_serial_ip_address_ipv6 ||
                ispInfo?.isp_provider
                  ?.wan_cr_serial_ip_address_ipv6_compressed) && (
                <div className="box-row">
                  <div>
                    <strong>WAN CR Serial IP:</strong>
                    {ispInfo?.wan_cr_serial_ip_address ? (
                      <span>{ispInfo?.wan_cr_serial_ip_address}</span>
                    ) : (
                      "NA"
                    )}
                  </div>
                  <div>
                    <strong>WAN CR Serial IPv6: </strong>
                    {ispInfo?.wan_cr_serial_ip_address_ipv6 ? (
                      <span>{ispInfo?.wan_cr_serial_ip_address_ipv6}</span>
                    ) : (
                      "NA"
                    )}
                  </div>
                  <div>
                    <strong>WAN CR Serial Compressed: </strong>
                    {ispInfo?.wan_cr_serial_ip_address_ipv6_compressed ? (
                      <span>
                        {ispInfo?.wan_cr_serial_ip_address_ipv6_compressed}
                      </span>
                    ) : (
                      "NA"
                    )}
                  </div>
                </div>
              )}

              {(ispInfo?.wan_ar_serial_ip_address ||
                ispInfo?.wan_ar_serial_ip_address_ipv6 ||
                ispInfo?.wan_ar_serial_ip_address_ipv6_compressed) && (
                <div className="box-row">
                  <div>
                    <strong>WAN AR Serial IP:</strong>
                    {ispInfo?.wan_ar_serial_ip_address ? (
                      <span>{ispInfo?.wan_ar_serial_ip_address}</span>
                    ) : (
                      "NA"
                    )}
                  </div>
                  <div>
                    <strong>WAN AR Serial IPv6: </strong>
                    {ispInfo?.wan_ar_serial_ip_address_ipv6 ? (
                      <span>{ispInfo?.wan_ar_serial_ip_address_ipv6}</span>
                    ) : (
                      "NA"
                    )}
                  </div>
                  <div>
                    <strong>WAN AR Serial Compressed: </strong>
                    {ispInfo?.wan_ar_serial_ip_address_ipv6_compressed ? (
                      <span>
                        {ispInfo?.wan_ar_serial_ip_address_ipv6_compressed}
                      </span>
                    ) : (
                      "NA"
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

          {ispInfo?.isp_provider?.isp_service_area && (
            <div className="descriptionView2 mt-10">
              <div className="box-row h-10 bg-black">ISP Service Areas</div>
              <div className="box-row">
                <Space>
                  <strong>ISP Service Area:</strong>
                  <span>
                    {getServiceAreaName(
                      ispInfo?.isp_provider?.isp_service_area
                    )}
                  </span>
                </Space>
                {ispInfo?.isp_provider?.isp_service_area === "SELECT_STATE" && (
                  <div>
                    <Space>
                      <strong>States:</strong>
                      {ispInfo?.isp_provider?.isp_service_area_states.join(
                        ", "
                      )}
                    </Space>
                  </div>
                )}
              </div>
            </div>
          )}

          {ispInfo?.isp_notes && (
            <div className="descriptionView2 mt-10">
              <div className="box-row h-10 bg-black">
                {" "}
                ISP Information : Notes
              </div>
              <div className="box-row" style={{ whiteSpace: "pre-line" }}>
                {ispInfo?.isp_notes}
              </div>
            </div>
          )}
        </div>
      </PageHeader>
    </div>
  );
}
