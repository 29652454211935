import React from 'react';
import { Route, Redirect, Link } from 'react-router-dom';
import { message, Breadcrumb, Button, Typography } from 'antd';
import axios from 'axios';
import Auth from 'auth';
import { accessControlListService } from 'services/access_control';

import { HomeOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
const { Title } = Typography;
const _auth = Auth;

export const axiosBase = {
  create() {
    return axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${_auth.getToken()}`,
      },
    });
  },
};

export const accessControls = {
  async fetch() {
    return new Promise((resolve, reject) => {
      var permissions = [];
      const role = 'current';
      accessControlListService(role)
        .then((response) => {
          if (response.data.items && response.data.items.length > 0) {
            const items = response.data.items;
            items.forEach((item) => {
              item.actions.forEach((actionItem) => {
                permissions[item.resource + '.' + actionItem.action] =
                  actionItem.access;
              });
            });
          }
          resolve(permissions);
        })
        .catch((error) => {
          reject(Error('sorry could notload permissions'));
          // dont react
        });
    });
  },

  can(permissions, resource_and_action) {
    if (Array.isArray(resource_and_action)) {
      let isAllowed = false;
      for (var i = 0; i < resource_and_action.length; i++) {
        if (permissions[resource_and_action[i]]) {
          isAllowed = true;
        }
      }
      return isAllowed;
    } else {
      if (permissions && permissions[resource_and_action]) {
        return permissions[resource_and_action];
      } else {
        return false;
      }
    }
  },
};

export function handleApiError(error) {
  if (error) {
    console.log('handleApiError:', error);

    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          message.error('Unauthorzied access!, Please login');
          _auth.signout();
          window.location.href = process.env.REACT_APP_APP_BASE_URL + '/login';
          break;
        default:
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            message.error(error.response.data.message);
          } else {
            message.error('Unknown error');
          }

          break;
      } //switch
    }
  } //if error
}

export function handleApiSuccess(success) {
  if (success) {
    if (success.data && success.data.message) {
      message.success(success.data.message);
    }
  }
}

export function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        _auth.isAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export function MyBreadcrumb(props) {
  const { items } = props;
  return (
    <Breadcrumb style={{ margin: '16px 0' }}>
      <Breadcrumb.Item>
        <Link to="/">
          <HomeOutlined />
        </Link>
      </Breadcrumb.Item>
      {items.map((item, i) => {
        if (item.path) {
          if (item.path === '/back') {
            return (
              <Breadcrumb.Item key={i}>
                <Button type="link" onClick={() => window.history.back()}>
                  {item.label}
                </Button>
              </Breadcrumb.Item>
            );
          } else {
            return (
              <Breadcrumb.Item key={i}>
                <Link to={item.path}>{item.label}</Link>
              </Breadcrumb.Item>
            );
          }
        } else {
          return <Breadcrumb.Item key={i}>{item.label}</Breadcrumb.Item>;
        }
      })}
    </Breadcrumb>
  );
}

export const CountriesJson = [
  {
    name: 'US',
    abbreviation: 'US',
  },
];

export const StatesJson = [
  {
    name: 'Alabama',
    abbreviation: 'AL',
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
  },
  {
    name: 'American Samoa',
    abbreviation: 'AS',
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
  },
  {
    name: 'California',
    abbreviation: 'CA',
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC',
  },
  {
    name: 'Federated States Of Micronesia',
    abbreviation: 'FM',
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
  },
  {
    name: 'Guam',
    abbreviation: 'GU',
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
  },
  {
    name: 'Marshall Islands',
    abbreviation: 'MH',
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
  },
  {
    name: 'New York',
    abbreviation: 'NY',
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
  },
  {
    name: 'Northern Mariana Islands',
    abbreviation: 'MP',
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
  },
  {
    name: 'Palau',
    abbreviation: 'PW',
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'PR',
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
  },
  {
    name: 'Virgin Islands',
    abbreviation: 'VI',
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
  },
];

export const msptrwStorage = {
  set(key, val) {
    localStorage.setItem(key, val);
  },
  get(key) {
    return new Promise((resolved, reject) => {
      let storedVal = localStorage.getItem(key);
      if (storedVal) {
        resolved(storedVal);
      } else {
        reject(null);
      }
    });
  },
};

export function confirmDelete(text) {
  return new Promise((resolved, reject) => {
    Swal.fire({
      title: 'Confirm!',
      text: text ? text : 'Are you sure you want to delete!',
      icon: 'warning',
      confirmButtonText: 'Ok',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        resolved(true);
      } else {
        reject(true);
      }
    });
  });
}

export function countryFullName(str) {
  switch (str) {
    case 'US':
      return 'United States of America';
    default:
      return str;
  }
}

export function IPtoNum(ip) {
  return Number(
    ip
      .split('.')
      .map((d) => ('000' + d).substr(-3))
      .join(''),
  );
}
export function checkIpValue(value) {
  const subips = value.split('.');
  if (subips.length > 4) {
    return false;
  }
  const invalidSubips = subips.filter((ip) => {
    ip = parseInt(ip);
    return ip < 0 || ip > 255;
  });
  if (invalidSubips.length !== 0) {
    return false;
  }
  let emptyIpCount = 0;
  subips.forEach((ip) => {
    if (ip === '') {
      emptyIpCount++;
    }
  });
  if (emptyIpCount > 1) {
    return false;
  }
  return true;
}

export function getServiceAreaName(type) {
  switch (type) {
    case 'NATION_WIDE':
      return 'Nation Wide';
    case 'SELECT_STATE':
      return 'Select a State';
    default:
      return 'N/A';
  }
}

export function calculateSubnetAndOthers(subnet, vlan) {
  if (!subnet) {
    return null;
  }
  const myArray = subnet.toString().split('.');
  if (!myArray[0] && !myArray[1]) {
    return null;
  }
  let payload = {};
  payload.subnet = `${myArray[0]}.${myArray[1]}.${vlan}.0`;
  payload.gateway = `${myArray[0]}.${myArray[1]}.${vlan}.1`;
  payload.dhcp_start = `${myArray[0]}.${myArray[1]}.${vlan}.51`;
  payload.dhcp_end = `${myArray[0]}.${myArray[1]}.${vlan}.254`;
  payload.staticIp_start = `${myArray[0]}.${myArray[1]}.${vlan}.2`;
  payload.staticIp_end = `${myArray[0]}.${myArray[1]}.${vlan}.50`;
  return payload;
}

export function niceName(str) {
  let i,
    frags = str.split('_');
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    if (frags[i] === 'Id') {
      frags[i] = 'ID';
    }
  }
  return frags.join(' ');
}

export function renderInfoBlock(payload) {
  let valueCount = 0;
  const itemsHtml = payload.items.map((item, index) => {
    let value = payload.source[item] || null;
    if (value && value !== '') {
      valueCount++;
    }
    if (typeof item === 'string' && value && value !== '') {
      return (
        <div>
          <strong>{niceName(item)}:</strong> {value}
        </div>
      );
    } else if (typeof item === 'object') {
      return (
        <div>
          <strong>{niceName(item.key)}:</strong> {item.value}
        </div>
      );
    } else {
      return null;
    }
  });
  /*
  {
    heading: "",
    source : {},
    items: [{key:"",value:""},"string"]
  }
  */
  if (valueCount > 0) {
    return (
      <div style={{ border: '1px solid #DDD', padding: 10, height: '100%' }}>
        <Title level={3}>{payload.heading}</Title>
        {itemsHtml}
      </div>
    );
  } else {
    return null;
  }
}

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    'Bytes',
    'KiB',
    'MiB',
    'GiB',
    'TiB',
    'PiB',
    'EiB',
    'ZiB',
    'YiB',
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
