import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Layout, Menu, Select, Popover } from 'antd';
import { Divider } from 'antd';
import {
  DashboardOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  CodeSandboxOutlined,
  GoldOutlined,
  TeamOutlined,
  HddOutlined,
  LogoutOutlined,
  ReadOutlined,
  RightOutlined,
  WifiOutlined,
  SettingOutlined,
  BankOutlined,
  BookOutlined,
  ApiOutlined,
  TableOutlined,
  SecurityScanOutlined,
  GlobalOutlined,
  DatabaseOutlined,
  DesktopOutlined,
  SolutionOutlined,
} from '@ant-design/icons';

import * as AntIcons from '@ant-design/icons';

import { clientsListService } from 'services/client';
import {
  usersSetLoginClientService,
  usersGetLoginClientService,
} from 'services/user';
import Auth from 'auth';
import { handleApiSuccess, handleApiError, accessControls } from 'utils';

import { isMobile } from 'react-device-detect';

const _auth = Auth;
const { Header, Content, Footer, Sider } = Layout;

const { SubMenu } = Menu;
const { Option } = Select;

const dynamicMenuGenerator = (dynamicRoutes, permissions) => {
  if (!dynamicRoutes) {
    return null;
  }

  const allMenus = dynamicRoutes.map((dynamicRoute) => {
    if (dynamicRoute.items && dynamicRoute.items.length > 0) {
      const SubMenuHtml = dynamicRoute.items.map((item, index) => {
        if (
          item.title &&
          item.url &&
          (!item.permission || accessControls.can(permissions, item.permission))
        ) {
          return (
            <Menu.Item key={item.key + '-' + index}>
              <Link to={item.url}>{item.title}</Link>
            </Menu.Item>
          );
        } else if (item.heading) {
          return (
            <Menu.Item key={item.key + '-' + index} className="menuHeading">
              <Divider>{item.heading}</Divider>
            </Menu.Item>
          );
        } else {
          return null;
        }
      });
      if (
        !dynamicRoute.permission ||
        accessControls.can(permissions, dynamicRoute.permission)
      ) {
        const MyIcon =
          dynamicRoute.icon && AntIcons[dynamicRoute['icon']]
            ? AntIcons[dynamicRoute['icon']]
            : CodeSandboxOutlined;
        return (
          <SubMenu
            icon={<MyIcon />}
            key={dynamicRoute.key}
            title={dynamicRoute.title}
          >
            {SubMenuHtml}
          </SubMenu>
        );
      } else {
        return null;
      }
    } else {
      const MyIcon =
        dynamicRoute.icon && AntIcons[dynamicRoute['icon']]
          ? AntIcons[dynamicRoute['icon']]
          : CodeSandboxOutlined;
      return !dynamicRoute.permission ||
        accessControls.can(permissions, dynamicRoute.permission) ? (
        <Menu.Item icon={<MyIcon />} key={dynamicRoute.key}>
          <Link to={dynamicRoute.url}>{dynamicRoute.title}</Link>
        </Menu.Item>
      ) : null;
    }
  });
  return allMenus;
};

class ControlPanelLayout extends React.Component {
  state = {
    permissions: [],
    clients: [],
    collapsed: isMobile ? true : false,
    activeClient: {},
    dynamicIcons: [],
  };

  async componentDidMount() {
    console.log(this.props.dynamicRoutes);
    clientsListService()
      .then((response) => {
        if (response.data.items && response.data.items.length > 0) {
          this.setState({ clients: response.data.items });
        }
      })
      .catch((error) => {
        //handleApiError(error);
      });

    usersGetLoginClientService()
      .then((response) => {
        if (response.data.items && response.data.items.length > 0) {
          this.setState({ activeClient: response.data.items[0] });
        }
      })
      .catch((error) => {
        handleApiError(error);
      });

    //********START  ACCESS CONTROL******** */
    accessControls.fetch().then((permissions) => {
      permissions['Workspace' + '.' + 'read:any'] = true;
      console.log(permissions);
      this.setState({ permissions });
    });
    //********END ACCESS CONTROL******** */
  }

  isNetworkingLayout = (pathname) => {
    const netowrkPagesArr = [
      '/countries',
      '/network-dashboard',
      '/states',
      '/bandwidth',
      '/print',
      '/equipmenttype',
      '/equipment-vendor',
      '/equipment-model',
      '/qosoptions',
      '/branchnetwork',
      '/branchcustomer',
      '/expectlog',
      '/expansiveworkspacelog',
      '/dashboardlog',
      '/dashboardlogactivity',
      '/vlanregistry',
      '/deviceregistry',
      '/officenumber',
      '/portregistry',
      '/ipportregistry',
      '/officeregistry',
      '/wifigroup',
      '/wifiusers',
      '/equipmentownership',
      '/clientfirewall',
      '/firewallports',
      '/map-ip-address-to-client',
    ];
    for (let i = 0; i < netowrkPagesArr.length; i++) {
      if (pathname.startsWith(netowrkPagesArr[i])) {
        return true;
      }
    }
    return false;
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  clientChanged = (value) => {
    usersSetLoginClientService(value)
      .then((response) => {
        _auth.authenticate(response.data.token, function () {
          handleApiSuccess(response);
          setTimeout(() => {
            window.location.href = process.env.REACT_APP_APP_BASE_URL;
          }, 1000);
        });
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  menuClickHandle = () => {
    if (isMobile) {
      this.toggle();
    }
    // On menu change clear local storage item
    let localStoredSearchQuery = localStorage.getItem('msptrw-kbSearchQuery');
    if (localStoredSearchQuery) {
      localStorage.removeItem('msptrw-kbSearchQuery');
    }
  };

  render() {
    const { pathname } = this.props.location;
    let defaultOpened = pathname;

    if (this.isNetworkingLayout(pathname)) {
      defaultOpened = ['/network-management'];
    } else if (pathname.startsWith('/knowledge-base')) {
      defaultOpened = ['/kb'];
    } else if (pathname.startsWith('/website')) {
      defaultOpened = ['/website'];
    } else {
      defaultOpened = [];
    }

    // NETWORK COMMANDER MENU
    if (process.env.REACT_APP_THEME === 'NETWORK_COMMANDER') {
      return (
        <Layout style={{ minHeight: '100vh' }}>
          <Sider
            width={250}
            collapsedWidth={isMobile ? 0 : 80}
            trigger={null}
            collapsible
            collapsed={this.state.collapsed}
            onCollapse={this.onCollapse}
            style={{
              overflow: 'auto',
              height: '100vh',
              position: 'fixed',
              zIndex: 99999,
              width: '250px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 80,
              }}
            >
              {process.env.REACT_APP_THEME === 'NETWORK_COMMANDER' ? (
                <img
                  src="/network-commander-logo-light-v3.png"
                  alt="Logo"
                  style={{ width: '80%' }}
                />
              ) : (
                <h3
                  style={{
                    color: '#fff',
                    fontSize: '1.8rem',
                    fontWeight: 'bold',
                  }}
                >
                  TechTRW
                </h3>
              )}
            </div>
            <Menu
              onClick={this.menuClickHandle}
              theme="dark"
              defaultSelectedKeys={pathname === '/' ? '/dashboard' : pathname}
              defaultOpenKeys={defaultOpened}
              mode="inline"
            >
              <Menu.Item
                key="/dashboard"
                icon={<DashboardOutlined style={{ fontSize: '16px' }} />}
              >
                <Link to="/">Dashboard</Link>
              </Menu.Item>
              {accessControls.can(
                this.state.permissions,
                'Client.read:any',
              ) && (
                <Menu.Item
                  key="/clients"
                  icon={<HddOutlined style={{ fontSize: '16px' }} />}
                >
                  <Link to="/clients">Resellers</Link>
                </Menu.Item>
              )}

              {accessControls.can(
                this.state.permissions,
                'NetworkingToolBranchCustomer.read:any',
              ) && (
                <Menu.Item
                  key="/branchcustomer"
                  icon={<BankOutlined style={{ fontSize: '16px' }} />}
                >
                  <Link to="/branchcustomer">Clients</Link>
                </Menu.Item>
              )}

              {accessControls.can(
                this.state.permissions,
                'NetworkingToolWifiUsers.read:any',
              ) && (
                <Menu.Item
                  key="/wifiusers"
                  icon={<WifiOutlined style={{ fontSize: '16px' }} />}
                >
                  <Link to="/wifiusers">Wireless</Link>
                </Menu.Item>
              )}

              {accessControls.can(
                this.state.permissions,
                'NetworkingToolOfficeRegistry.read:any',
              ) && (
                <Menu.Item
                  key="/officeregistry"
                  icon={<ApiOutlined style={{ fontSize: '16px' }} />}
                >
                  <Link to="/officeregistry">Office Assignment</Link>
                </Menu.Item>
              )}

              {accessControls.can(
                this.state.permissions,
                'NetworkingToolPortRegistry.read:any',
              ) && (
                <Menu.Item
                  key="/officenumber"
                  icon={<BookOutlined style={{ fontSize: '16px' }} />}
                >
                  <Link to="/officenumber">Office Numbers</Link>
                </Menu.Item>
              )}

              {accessControls.can(
                this.state.permissions,
                'NetworkingToolPortRegistry.read:any',
              ) && (
                <Menu.Item
                  key="/portregistry"
                  icon={<ApiOutlined style={{ fontSize: '16px' }} />}
                >
                  <Link to="/portregistry">Switch Port Registry</Link>
                </Menu.Item>
              )}

              {accessControls.can(
                this.state.permissions,
                'RadDbReports.read:any',
              ) && (
                <Menu.Item
                  key="/raddb-reports"
                  icon={<DatabaseOutlined style={{ fontSize: '16px' }} />}
                >
                  <Link to="/raddb-reports">Login Attempts</Link>
                </Menu.Item>
              )}

              {accessControls.can(this.state.permissions, 'Isp.read:any') && (
                <Menu.Item
                  key="/isp"
                  icon={<GlobalOutlined style={{ fontSize: '16px' }} />}
                >
                  <Link to="/isp">ISP Management</Link>
                </Menu.Item>
              )}

              {accessControls.can(
                this.state.permissions,
                'NetworkingToolMapIpAddressToClient.read:any',
              ) && (
                <Menu.Item
                  key="/map_ipaddress_to_client"
                  icon={<GlobalOutlined style={{ fontSize: '16px' }} />}
                >
                  <Link to="/map-ip-address-to-client">
                    Map Public IP to Client
                  </Link>
                </Menu.Item>
              )}

              {accessControls.can(
                this.state.permissions,
                'NetworkingToolMapIpAddressToClient.read:any',
              ) && (
                <Menu.Item
                  key="/map-port-to-client"
                  icon={<GlobalOutlined style={{ fontSize: '16px' }} />}
                >
                  <Link to="/map-port-to-client">Map Port to Client</Link>
                </Menu.Item>
              )}

              {accessControls.can(
                this.state.permissions,
                'UtilityProviders.read:any',
              ) && (
                <Menu.Item
                  key="/utility-providers"
                  icon={<SecurityScanOutlined style={{ fontSize: '16px' }} />}
                >
                  <Link to="/utility-providers">Utility Providers</Link>
                </Menu.Item>
              )}

              {accessControls.can(
                this.state.permissions,
                'UtilityProviders.read:any',
              ) && (
                <Menu.Item
                  key="/service-providers"
                  icon={<TableOutlined style={{ fontSize: '16px' }} />}
                >
                  <Link to="/service-providers">Service Providers</Link>
                </Menu.Item>
              )}

              <Menu.Item
                key="/logout"
                icon={<LogoutOutlined style={{ fontSize: '16px' }} />}
              >
                <Link to="/logout">Logout</Link>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout
            className="site-layout"
            style={
              this.state.collapsed
                ? isMobile
                  ? {}
                  : { marginLeft: 80 }
                : { marginLeft: 250 }
            }
          >
            <Header className="site-layout-background" style={{ padding: 0 }}>
              {React.createElement(
                this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: 'trigger',
                  onClick: this.toggle,
                },
              )}

              {this.state.clients && this.state.clients.length > 0 && (
                <div style={{ display: 'inline' }}>
                  <span className="clientLabel">
                    <span style={{ letterSpacing: 2 }}>RESELLER</span>{' '}
                    &nbsp;&nbsp;
                    <RightOutlined style={{ color: '#EAEAEA' }} />
                  </span>
                  <Popover
                    content={
                      <p>Please select client to load associated data</p>
                    }
                    title="Select Reseller"
                    visible={this.state.activeClient._id ? false : true}
                  >
                    <Select
                      showSearch
                      placeholder="Select a client"
                      optionFilterProp="children"
                      className="selectClient"
                      onChange={this.clientChanged}
                      defaultValue={this.state.activeClient._id}
                      value={this.state.activeClient._id}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      style={{ minWidth: 150, maxWidth: 200 }}
                    >
                      {this.state.clients.map((item, index) => (
                        <Option key={index} value={item._id}>
                          {item.client_name}
                        </Option>
                      ))}
                    </Select>
                  </Popover>
                </div>
              )}
            </Header>

            <Content style={{ margin: '0 16px' }}>
              {this.props.children}
            </Content>
            <Footer style={{ textAlign: 'center' }}>
              &copy;{new Date().getFullYear()}{' '}
              {process.env.REACT_APP_THEME === 'NETWORK_COMMANDER'
                ? 'Network Commander'
                : 'TechTRW'}{' '}
              - All right reserved
            </Footer>
          </Layout>
        </Layout>
      );
    } else {
      // DEFAULT MENU
      return (
        <Layout style={{ minHeight: '100vh' }}>
          <Sider
            width={250}
            collapsedWidth={isMobile ? 0 : 80}
            trigger={null}
            collapsible
            collapsed={this.state.collapsed}
            onCollapse={this.onCollapse}
            style={{
              overflow: 'auto',
              height: '100vh',
              position: 'fixed',
              zIndex: 99999,
              width: '250px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 80,
              }}
            >
              {process.env.REACT_APP_THEME === 'NETWORK_COMMANDER' ? (
                <img
                  src="/network-commander-logo-light-v3.png"
                  alt="Logo"
                  style={{ width: '80%' }}
                />
              ) : (
                <h3
                  style={{
                    color: '#fff',
                    fontSize: '1.8rem',
                    fontWeight: 'bold',
                  }}
                >
                  TechTRW
                </h3>
              )}
            </div>
            <Menu
              onClick={this.menuClickHandle}
              theme="dark"
              defaultSelectedKeys={pathname === '/' ? '/dashboard' : pathname}
              defaultOpenKeys={defaultOpened}
              mode="inline"
            >
              <Menu.Item
                key="/dashboard"
                icon={<DashboardOutlined style={{ fontSize: '16px' }} />}
              >
                <Link to="/">Dashboard</Link>
              </Menu.Item>
              {accessControls.can(
                this.state.permissions,
                'Client.read:any',
              ) && (
                <Menu.Item
                  key="/clients"
                  icon={<HddOutlined style={{ fontSize: '16px' }} />}
                >
                  <Link to="/clients">Resellers</Link>
                </Menu.Item>
              )}
              {accessControls.can(
                this.state.permissions,
                'AccessControl.read:any',
              ) && (
                <Menu.Item
                  key="/access-control"
                  icon={<DashboardOutlined style={{ fontSize: '16px' }} />}
                >
                  <Link to="/access-control">Access Control</Link>
                </Menu.Item>
              )}
              {/* Show companies section only if have create or update permission */}
              {(accessControls.can(
                this.state.permissions,
                'Company.create:any',
              ) ||
                accessControls.can(
                  this.state.permissions,
                  'Company.update:any',
                )) && (
                <Menu.Item
                  key="/companies"
                  icon={<CodeSandboxOutlined style={{ fontSize: '16px' }} />}
                >
                  <Link to="/companies">Companies</Link>
                </Menu.Item>
              )}
              {accessControls.can(
                this.state.permissions,
                'Branch.read:any',
              ) && (
                <Menu.Item
                  key="/branches"
                  icon={<GoldOutlined style={{ fontSize: '16px' }} />}
                >
                  <Link to="/branches">Branches</Link>
                </Menu.Item>
              )}
              {accessControls.can(
                this.state.permissions,
                'WorkspaceManagement.read:any',
              ) && (
                <Menu.Item
                  key="/workspace"
                  icon={<DesktopOutlined style={{ fontSize: '16px' }} />}
                >
                  <Link to="/workspace">Workspace</Link>
                </Menu.Item>
              )}
              {accessControls.can(
                this.state.permissions,
                'WorkspaceManagement.read:any',
              ) && (
                <Menu.Item
                  key="/sales"
                  icon={<SolutionOutlined style={{ fontSize: '16px' }} />}
                >
                  <Link to="/sales">Sales Person</Link>
                </Menu.Item>
              )}
              {accessControls.can(this.state.permissions, 'User.read:any') && (
                <Menu.Item
                  key="/users"
                  icon={<TeamOutlined style={{ fontSize: '16px' }} />}
                >
                  <Link to="/users">Users</Link>
                </Menu.Item>
              )}

              {accessControls.can(this.state.permissions, 'User.read:any') && (
                <Menu.Item
                  key="/third-party-credentials"
                  icon={<TeamOutlined style={{ fontSize: '16px' }} />}
                >
                  <Link to="/third-party-credentials">
                    Third-Party Credentials
                  </Link>
                </Menu.Item>
              )}

              {accessControls.can(
                this.state.permissions,
                'IspProvider.read:any',
              ) && (
                <Menu.Item
                  key="/manage-isp-provider"
                  icon={<WifiOutlined style={{ fontSize: '16px' }} />}
                >
                  <Link to="/manage-isp-provider">ISP Vendors</Link>
                </Menu.Item>
              )}

              {accessControls.can(this.state.permissions, 'Isp.read:any') && (
                <Menu.Item
                  key="/isp"
                  icon={<WifiOutlined style={{ fontSize: '16px' }} />}
                >
                  <Link to="/isp">ISP Management</Link>
                </Menu.Item>
              )}

              {accessControls.can(
                this.state.permissions,
                'UtilityProviders.read:any',
              ) && (
                <Menu.Item
                  key="/utility-providers"
                  icon={<WifiOutlined style={{ fontSize: '16px' }} />}
                >
                  <Link to="/utility-providers">Utility Providers</Link>
                </Menu.Item>
              )}

              {accessControls.can(
                this.state.permissions,
                'UtilityProviders.read:any',
              ) && (
                <Menu.Item
                  key="/service-providers"
                  icon={<WifiOutlined style={{ fontSize: '16px' }} />}
                >
                  <Link to="/service-providers">Service Providers</Link>
                </Menu.Item>
              )}

              {(accessControls.can(
                this.state.permissions,
                'KBCategory.read:any',
              ) ||
                accessControls.can(
                  this.state.permissions,
                  'KBPost.read:any',
                )) && (
                <SubMenu
                  key="/kb"
                  icon={<ReadOutlined />}
                  title="Knowldge Base (KB)"
                >
                  {accessControls.can(
                    this.state.permissions,
                    'KBCategory.read:any',
                  ) && (
                    <Menu.Item key="/knowledge-base/categories">
                      <Link to="/knowledge-base/categories">Categories</Link>
                    </Menu.Item>
                  )}
                  {(accessControls.can(
                    this.state.permissions,
                    'KBPost.create:any',
                  ) ||
                    accessControls.can(
                      this.state.permissions,
                      'KBPost.update:any',
                    )) && (
                    <Menu.Item key="/manageKB">
                      <Link to="/knowledge-base">Manage Posts</Link>
                    </Menu.Item>
                  )}
                  {accessControls.can(
                    this.state.permissions,
                    'KBPost.read:any',
                  ) && (
                    <Menu.Item key="/knowledge-base/view">
                      <Link to="/knowledge-base/view">View</Link>
                    </Menu.Item>
                  )}
                </SubMenu>
              )}

              {dynamicMenuGenerator(
                this.props.dynamicRoutes,
                this.state.permissions,
              )}

              {accessControls.can(
                this.state.permissions,
                'AdminControl.read:any',
              ) && (
                <Menu.Item
                  key="/admincontrol"
                  icon={<SettingOutlined style={{ fontSize: '16px' }} />}
                >
                  <Link to="/admincontrol">Admin Controls</Link>
                </Menu.Item>
              )}

              <Menu.Item
                key="/logout"
                icon={<LogoutOutlined style={{ fontSize: '16px' }} />}
              >
                <Link to="/logout">Logout</Link>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout
            className="site-layout"
            style={
              this.state.collapsed
                ? isMobile
                  ? {}
                  : { marginLeft: 80 }
                : { marginLeft: 250 }
            }
          >
            <Header className="site-layout-background" style={{ padding: 0 }}>
              {React.createElement(
                this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: 'trigger',
                  onClick: this.toggle,
                },
              )}

              {this.state.clients && this.state.clients.length > 0 && (
                <div style={{ display: 'inline' }}>
                  <span className="clientLabel">
                    <span style={{ letterSpacing: 2 }}>RESELLER</span>{' '}
                    &nbsp;&nbsp;
                    <RightOutlined style={{ color: '#EAEAEA' }} />
                  </span>
                  <Popover
                    content={
                      <p>Please select client to load associated data</p>
                    }
                    title="Select Reseller"
                    visible={this.state.activeClient._id ? false : true}
                  >
                    <Select
                      showSearch
                      placeholder="Select a client"
                      optionFilterProp="children"
                      className="selectClient"
                      onChange={this.clientChanged}
                      defaultValue={this.state.activeClient._id}
                      value={this.state.activeClient._id}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      style={{ minWidth: 150, maxWidth: 200 }}
                    >
                      {this.state.clients.map((item, index) => (
                        <Option key={index} value={item._id}>
                          {item.client_name}
                        </Option>
                      ))}
                    </Select>
                  </Popover>
                </div>
              )}
            </Header>

            <Content style={{ margin: '0 16px' }}>
              {this.props.children}
            </Content>
            <Footer style={{ textAlign: 'center' }}>
              &copy;{new Date().getFullYear()}{' '}
              {process.env.REACT_APP_THEME === 'NETWORK_COMMANDER'
                ? 'Network Commander'
                : 'TechTRW'}{' '}
              - All right reserved
            </Footer>
          </Layout>
        </Layout>
      );
    }
  }
}
export default withRouter(ControlPanelLayout);
