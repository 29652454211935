import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Space, Button, Form, Input, Divider, Select, DatePicker } from 'antd';
import {
  ispListByIdService,
  ispCreateService,
  ispUpdateService,
} from 'services/isp';

import { ispProviderListService } from 'services/isp_provider';

import {
  handleApiSuccess,
  handleApiError,
  CountriesJson,
  StatesJson,
  checkIpValue,
} from 'utils';

import InputMask from 'react-input-mask';

import {
  LockOutlined,
  ShopOutlined,
  PhoneOutlined,
  IdcardOutlined,
  UserOutlined,
  GlobalOutlined,
} from '@ant-design/icons';

import CompanyBranchDropdownForm from './../../utils/CompanyBranchDropdownForm';

import moment from 'moment';

const { Option } = Select;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 7 },
};
const tailLayout = {
  wrapperCol: { offset: 5, span: 7 },
};

export default function IspForm(props) {
  const { id } = props;

  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [form] = Form.useForm();

  const [ispProvider, setIspProvider] = useState([]);

  useEffect(() => {
    // IF id exist the initialize form data
    if (id) {
      ispListByIdService(id)
        .then((response) => {
          setIsLoading(false);
          if (response.data.item) {
            let formData = response.data.item;

            if (props.pageTitleCallback) {
              props.pageTitleCallback(
                `${formData.company_name} - ${formData.branch_name} - ${formData.isp_provider_name}`,
              );
            }

            if (formData.contract_start !== '') {
              formData.contract_start = moment(formData.contract_start);
            }
            if (formData.contract_end !== '') {
              formData.contract_end = moment(formData.contract_end);
            }

            form.setFieldsValue(formData);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    }

    //Load ISP Provider dropdown
    ispProviderListService().then((response) => {
      if (response.data.items && response.data.items.length > 0) {
        setIspProvider(response.data.items);
      }
    });
  }, [id, form]);

  const onFinish = (values) => {
    setIsLoading(true);

    // Update
    if (id) {
      ispUpdateService(id, values)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          history.push('/isp');
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    } else {
      // Create
      ispCreateService(values)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          history.goBack();
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    }
  };

  const LandlineInput = (props) => (
    <InputMask
      mask="999-999-9999"
      value={props.value}
      onChange={props.onChange}
    >
      {(inputProps) => (
        <Input
          {...inputProps}
          style={{ width: '70%' }}
          prefix={<PhoneOutlined />}
        />
      )}
    </InputMask>
  );

  const IpInput = (props) => (
    <InputMask
      // mask="999.999.999.999"
      formatChars={{
        9: '[0-9.]',
      }}
      mask="999999999999999"
      maskChar={null}
      alwaysShowMask={false}
      beforeMaskedValueChange={(newState, oldState, userInput) => {
        let value = newState.value;
        const oldValue = oldState.value;
        let selection = newState.selection;
        let cursorPosition = selection ? selection.start : null;
        const result = checkIpValue(value);
        if (!result) {
          value = value.trim();
          // try to add . before the last char to see if it is valid ip address
          const newValue =
            value.substring(0, value.length - 1) +
            '.' +
            value.substring(value.length - 1);
          if (checkIpValue(newValue)) {
            cursorPosition++;
            selection = { start: cursorPosition, end: cursorPosition };
            value = newValue;
          } else {
            value = oldValue;
          }
        }

        return {
          value,
          selection,
        };
      }}
      value={props.value}
      onChange={props.onChange}
    >
      {(inputProps) => (
        <Input
          {...inputProps}
          style={{ width: '70%' }}
          prefix={<GlobalOutlined />}
        />
      )}
    </InputMask>
  );

  const EmailInput = (props) => (
    <Input {...props} style={{ width: '70%' }} prefix={<IdcardOutlined />} />
  );

  return (
    <Form name="myForm" form={form} {...layout} onFinish={onFinish}>
      <Divider orientation="left">
        <LockOutlined /> Branch Profile
      </Divider>
      <Space>&nbsp;</Space>
      <CompanyBranchDropdownForm
        company={form.getFieldValue('company')}
        branch={form.getFieldValue('branch')}
      />
      {/*
      <div
        style={{
          backgroundColor: "#FAFAFA",
          border: "1px solid #EAEAEA",
          padding: "30px 5px 5px 5px",
          marginBottom: 30,
        }}
      >
        <Form.Item
          label="Company"
          name="company"
          rules={[{ required: true, message: "Please select Company" }]}
        >
          <Select
            autoComplete="nope"
            showSearch
            placeholder="Select a company"
            optionFilterProp="children"
            onChange={(value) => onCompanyChange(value)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="">Select a company</Option>
            {companies.map((item, i) => (
              <Option value={item._id}>{item.company_name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Branch"
          name="branch"
          rules={[
            { required: true, message: "Please select Company & Branch" },
          ]}
        >
          <Select
            autoComplete="nope"
            showSearch
            style={{ width: 200 }}
            placeholder="Select a branch"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="">Select a branch</Option>
            {branches.map((item, i) => (
              <Option value={item._id}>{item.branch_name}</Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      */}
      <Form.Item label="Account Number" name="account_number">
        <Input />
      </Form.Item>
      <Form.Item label="Circuit ID" name="circuit_id">
        <Input />
      </Form.Item>
      <Form.Item
        label="Status"
        name="status"
        rules={[{ required: true, message: 'Please select Status' }]}
      >
        <Select
          autoComplete="nope"
          showSearch
          style={{ width: 200 }}
          placeholder="Select status"
          optionFilterProp="children"
        >
          <Option value="ACTIVE">Active</Option>
          <Option value="INACTIVE">Inactive</Option>
          <Option value="TO_BE_RENEWED">To Be Renewed</Option>
          <Option value="TO_BE_CANCELED">To Be Cancelled</Option>
          <Option value="ACTIVE_PRIMARY">Active Primary</Option>
          <Option value="ACTIVE_SECONDARY">Active Secondary</Option>
        </Select>
      </Form.Item>

      <Form.Item label="Duplex" name="duplex">
        <Select
          autoComplete="nope"
          showSearch
          style={{ width: 200 }}
          placeholder="Select duplex"
          optionFilterProp="children"
        >
          <Option value="Half">Half</Option>
          <Option value="Full">Full</Option>
        </Select>
      </Form.Item>
      <Form.Item label="Circuit use" name="circuit_use">
        <Select
          autoComplete="nope"
          showSearch
          style={{ width: 200 }}
          placeholder="Select circuit use"
          optionFilterProp="children"
        >
          <Option value="Primary">Primary</Option>
          <Option value="Secondary">Secondary</Option>
          <Option value="Special Use">Special Use</Option>
          <Option value="SD-WAN">SD-WAN</Option>
          <Option value="Dedicated to Customer">Dedicated to Customer</Option>
          <Option value="Canceled">Canceled</Option>
          <Option value="Cancel in the Future">Cancel in the Future</Option>
        </Select>
      </Form.Item>
      <Form.Item label="Failover" name="failover">
        <Select
          autoComplete="nope"
          showSearch
          style={{ width: 200 }}
          placeholder="Select failover"
          optionFilterProp="children"
        >
          <Option value="None">None</Option>
          <Option value="Automatic">Automatic</Option>
          <Option value="Manual">Manual</Option>
        </Select>
      </Form.Item>
      <Form.Item label="Net On/Off" name="onoff_net">
        <Select
          autoComplete="nope"
          showSearch
          style={{ width: 200 }}
          placeholder="Select On/Off"
          optionFilterProp="children"
        >
          <Option value="ON">ON</Option>
          <Option value="OFF">OFF</Option>
        </Select>
      </Form.Item>
      <Form.Item label="Download Speed (Mbps)" name="download_speed_mbps">
        <Input />
      </Form.Item>
      <Form.Item label="Upload Speed (Mbps)" name="upload_speed_mbps">
        <Input />
      </Form.Item>

      <Divider orientation="left">
        <ShopOutlined /> Contract Information
      </Divider>
      <Space>&nbsp;</Space>
      <Form.Item label="Monthly Fee" name="monthly_fee">
        <Input />
      </Form.Item>
      <Form.Item label="Contract Start" name="contract_start">
        <DatePicker />
      </Form.Item>
      <Form.Item label="Contract End" name="contract_end">
        <DatePicker />
      </Form.Item>

      <Form.Item
        help="example: '3 Month' or '1 Year'"
        label="Terms length"
        name="term_length_months"
      >
        <Input />
      </Form.Item>

      <Form.Item label="Installation Fee" name="installation_fee">
        <Input />
      </Form.Item>
      <Form.Item label="Contract Signed By" name="contract_signedby">
        <Input />
      </Form.Item>

      <Form.Item label="SLA" name="sla">
        <Input />
      </Form.Item>
      <Divider orientation="left">
        <ShopOutlined /> Credentials
      </Divider>
      <Space>&nbsp;</Space>
      <Form.Item label="Primary on Account" name="primary_on_account">
        <Input />
      </Form.Item>
      <Form.Item label="PIN" name="pin">
        <Input />
      </Form.Item>
      <Form.Item label="Security Question" name="security_question">
        <Input />
      </Form.Item>
      <Form.Item label="Phone Number" name="phone_number">
        <LandlineInput />
      </Form.Item>
      <Form.Item label="Username" name="username">
        <Input />
      </Form.Item>
      <Form.Item label="Password" name="password">
        <Input />
      </Form.Item>
      <Form.Item label="Website" name="website">
        <Input />
      </Form.Item>
      <Form.Item label="Email" name="email">
        <EmailInput />
      </Form.Item>
      <Divider orientation="left">
        <UserOutlined /> Location
      </Divider>
      <Form.Item label="Location Name" name="location_name">
        <Input />
      </Form.Item>

      <Form.Item label="Node ID" name="node_id">
        <Input />
      </Form.Item>
      <Divider orientation="left">
        <ShopOutlined /> Billing Address
      </Divider>
      <Form.Item label="Billing Name" name="billing_name">
        <Input />
      </Form.Item>
      <Form.Item label="Billing Address 1" name="billing_address1">
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="Billing Address 2" name="billing_address2">
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="Billing City" name="billing_city">
        <Input />
      </Form.Item>
      <Form.Item label="Billing State" name="billing_state">
        <Select
          autoComplete="nope"
          showSearch
          style={{ width: 200 }}
          placeholder="Select state"
          optionFilterProp="children"
        >
          {StatesJson.map((item, i) => (
            <Option value={item.abbreviation}>{item.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Billing Zip Code" name="billing_zip">
        <Input />
      </Form.Item>
      <Form.Item label="Billing Country" name="billing_country">
        <Select
          autoComplete="nope"
          showSearch
          style={{ width: 200 }}
          placeholder="Select country"
          optionFilterProp="children"
        >
          {CountriesJson.map((item, i) => (
            <Option value={item.abbreviation}>{item.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Billing Contact" name="billing_contact">
        <Input />
      </Form.Item>
      <Form.Item label="Billing Phone" name="billing_phone">
        <LandlineInput />
      </Form.Item>
      <Form.Item label="Billing Email" name="billing_email">
        <Input />
      </Form.Item>
      <Form.Item label="Billing Fax" name="billing_fax">
        <LandlineInput />
      </Form.Item>
      <Divider orientation="left">
        <UserOutlined /> ISP Information
      </Divider>
      <Form.Item
        label="ISP Provider"
        name="isp_provider"
        rules={[{ required: true, message: 'Please select ISP Provider' }]}
      >
        <Select
          autoComplete="nope"
          showSearch
          placeholder="Select ISP Provider"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option value="">Select ISP Provider</Option>
          {ispProvider.map((item, i) => (
            <Option value={item._id}>{item.provider_name}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="DNS Resolver 1" name="isp_dns_resolver1">
        <Input />
      </Form.Item>
      <Form.Item label="DNS Resolver 2" name="isp_dns_resolver2">
        <Input />
      </Form.Item>
      <Form.Item label="ISP DNS Resolver IPV6 1" name="isp_dns_resolver_ipv6_1">
        <Input />
      </Form.Item>
      <Form.Item label="ISP DNS Resolver IPV6 2" name="isp_dns_resolver_ipv6_2">
        <Input />
      </Form.Item>
      <Form.Item label="DNS Authoritative 1" name="isp_dns_authoritative1">
        <Input />
      </Form.Item>
      <Form.Item label="DNS Authoritative 2" name="isp_dns_authoritative2">
        <Input />
      </Form.Item>
      <Form.Item label="Sales Agent Email" name="isp_sales_agent_email">
        <EmailInput />
      </Form.Item>
      <Form.Item label="Sales Agent Phone" name="isp_sales_agent_phone">
        <LandlineInput />
      </Form.Item>
      <Form.Item label="Sales Agent Name" name="isp_sales_agent_name">
        <Input />
      </Form.Item>
      <Form.Item label="Notes" name="isp_notes">
        <Input.TextArea />
      </Form.Item>
      <Divider orientation="left">
        <UserOutlined /> Notices Address
      </Divider>
      <Form.Item label="Address 1" name="notices_address1">
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="Address 2" name="notices_address2">
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="City" name="notices_city">
        <Input />
      </Form.Item>
      <Form.Item label="State" name="notices_state">
        <Select
          autoComplete="nope"
          showSearch
          style={{ width: 200 }}
          placeholder="Select state"
          optionFilterProp="children"
        >
          {StatesJson.map((item, i) => (
            <Option value={item.abbreviation}>{item.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Zip Code" name="notices_zip">
        <Input />
      </Form.Item>
      <Form.Item label="Country" name="notices_country">
        <Select
          autoComplete="nope"
          showSearch
          style={{ width: 200 }}
          placeholder="Select country"
          optionFilterProp="children"
        >
          {CountriesJson.map((item, i) => (
            <Option value={item.abbreviation}>{item.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Divider orientation="left">
        <ShopOutlined /> Site Information
      </Divider>
      <Space>&nbsp;</Space>

      <Form.Item label="Branch WAN" name="branch_wan">
        <Input />
      </Form.Item>
      <Form.Item label="Carrier WAN" name="carrier_wan">
        <Input />
      </Form.Item>
      <Form.Item label="WAN IP Address" name="wan_ip_address">
        <Input />
      </Form.Item>
      <Form.Item label="Branch WAN IPV6 Address" name="branch_wan_ipv6_address">
        <Input />
      </Form.Item>

      <Form.Item label="Branch WAN CIDR" name="branch_wan_cidr">
        <Input />
      </Form.Item>
      <Form.Item label="Carrier WAN CIDR" name="carrier_wan_cidr">
        <Input />
      </Form.Item>

      <Form.Item label="Branch Subnet Mask" name="branch_subnet_mask">
        <Input />
      </Form.Item>
      <Form.Item label="Branch Subnet Mask 2" name="branch_subnet_mask2">
        <Input />
      </Form.Item>
      <Form.Item label="Carrier Subnet Mask" name="carrier_subnet_mask">
        <Input />
      </Form.Item>

      <Form.Item
        label="Branch IPV4 Address Block"
        name="branch_ipv4_address_block"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Branch IPV4 Address Block 2"
        name="branch_ipv4_address_block2"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Branch IPV6 Address Block"
        name="branch_ipv6_address_block"
      >
        <Input />
      </Form.Item>

      <Space>&nbsp;</Space>
      <Form.Item label="Branch IP Gateway" name="branch_ip_gateway">
        <Input />
      </Form.Item>
      <Form.Item label="Branch IP Gateway IPv6" name="branch_ip_gateway_ipv6">
        <Input />
      </Form.Item>
      <Form.Item label="Carrier IP Gateway IPv6" name="carrier_ip_gateway_ipv6">
        <Input />
      </Form.Item>
      <Form.Item
        label="Branch First Assignable IP"
        name="branch_first_assignable_ip"
      >
        <IpInput />
      </Form.Item>
      <Form.Item
        label="Branch Last Assignable IP"
        name="branch_last_assignable_ip"
      >
        <IpInput />
      </Form.Item>
      <Form.Item
        label="Branch First Assignable IPV6"
        name="branch_first_assignable_ipv6"
      >
        <Input />
      </Form.Item>
      <Form.Item label="Branch Broadcast IP" name="branch_broadcast_ip">
        <Input />
      </Form.Item>
      <Form.Item label="Carrier Usable IP Range" name="carrier_usable_ip_range">
        <Input />
      </Form.Item>

      <Form.Item label="CR Serial IP Address" name="wan_cr_serial_ip_address">
        <Input />
      </Form.Item>
      <Form.Item
        label="CR Serial IP Address IPV6"
        name="wan_cr_serial_ip_address_ipv6"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="CR Serial IP Address IPV6 Compressed"
        name="wan_cr_serial_ip_address_ipv6_compressed"
      >
        <Input />
      </Form.Item>
      <Form.Item label="AR Serial IP Address" name="wan_ar_serial_ip_address">
        <Input />
      </Form.Item>
      <Form.Item
        label="AR Serial IP Address IPV6"
        name="wan_ar_serial_ip_address_ipv6"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="AR Serial IP Address IPV6 Compressed"
        name="wan_ar_serial_ip_address_ipv6_compressed"
      >
        <Input />
      </Form.Item>

      <Form.Item label="Demarc" name="demarc">
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="Link Type" name="link_type">
        <Select
          autoComplete="nope"
          showSearch
          style={{ width: 200 }}
          placeholder="Select link type"
          optionFilterProp="children"
        >
          <Option value="Ethernet">Ethernet</Option>
          <Option value="Fiber">Fiber</Option>
          <Option value="Gigabit Ethernet">Gigabit Ethernet</Option>
        </Select>
      </Form.Item>
      <Form.Item label="Interface Type" name="interface_type">
        <Input />
      </Form.Item>
      <Form.Item label="Rack Info" name="rack_info">
        <Input />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type="primary" loading={isLoading} htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
